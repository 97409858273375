import { Container } from "react-bootstrap";
import { useState, useEffect } from "react";
import ThemedButtonIcon from "../components/ThemedButtonIcon";
import CarouselThumbNails from "../components/CarouselThumbNail";
import LargeImageModal from "../components/LargeImageModal";

import newYearVideo from "../assets/NewYear24/videos/newyear.mp4";
import "./stylefiles/gallery.css";
// import "../styles/largeImage.css";
import "../components/stylefiles/largeImage.css";
import "../components/stylefiles/videoSetting.css"

// import ShowGalleryImages from "../components/ShowGalleryImages";
import ShowLargeImage from "../components/ShowGalleryImages";
import ShowImageUnorderedList from "../components/ShowImagesUnorderedList";
import ShowVideo from "../components/ShowVideo";
import RdgNews from "../assets/NewYear25/homescreen/RDG.TODAY.gif";


function GalleryNew() {

  const [images, setImages] = useState({});
  const [thumbnails, setThumbNails] = useState({});
  // const [items, setItems] = useState({ childrenday24: true });
  const [items, setItems] = useState({ newyear25: true });

  const [workShops24, setWorkshops24Images] = useState({});
  const [outdoor24, setOutdoor24Images] = useState({});
  const [performance24, setPerformance24Images] = useState({});
  
  const [currentImage, setCurrentImage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const [childrenPerform, setChildrenPerformImages] = useState({})
  const [newyear25, setNewYear25Images] = useState({})

  function handleClick(e) {

    const name = e.target.name;
    setItems(() => {
      var itemsSelected = {
        newyear23: false,
        newyear24: false,
        childrenday24: false,
        newyear25:false
      }
      itemsSelected[name] = true;
      return itemsSelected;
    })
  }

  function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); return 0; });
    
    return images;

  }


  
  function handleAllImageClick(name, id) {
    setIsOpen(true);

    /*id: 0 for workshop images, 1 for outdoor images, and 2 for perfomance Images， 3 for newyear25 images*/
    if (id === 0) {
      // console.log("Gallery clicked")
      setCurrentImage(workShops24[name]);
    } else if (id === 1) {
      // console.log("Gallery clicked")
      setCurrentImage(outdoor24[name]);
    } else if (id === 2) {
      // console.log("Gallery clicked")
      setCurrentImage(performance24[name]);
    } else if(id === 3){
      setCurrentImage(newyear25[name])
    }

  }


  useEffect(() => {


    setImages(importAll(require.context('../assets/NewYear23', false, /\.(png|jpe?g|jfif|svg)$/)));
    setThumbNails(importAll(require.context('../assets/NewYear23_thumbnail', false, /\.(png|jpe?g|jfif|svg)$/)));
    setWorkshops24Images(importAll(require.context('../assets/NewYear24/workshops', false, /\.(png|jpe?g|jfif|svg)$/)));
    setOutdoor24Images(importAll(require.context('../assets/NewYear24/outdoor', false, /\.(png|jpe?g|jfif|svg)$/)));
    setPerformance24Images(importAll(require.context('../assets/NewYear24/performance', false, /\.(png|jpe?g|jfif|svg)$/)));

    setChildrenPerformImages(importAll(require.context('../assets/ChildrenDay24/performance', false, /\.(png|jpe?g|jfif|svg)$/)));
    setNewYear25Images(importAll(require.context('../assets/NewYear25/homescreen', false, /\.(png|jpe?g|jfif|svg|webp)$/)));

    // console.log(childrenPerform)
  }, []);


  return (<Container fluid className="pageDetailsCollum">
    <div className="pageTitleDiv">GALLERY</div>

    <Container fluid className="pageMenuButtonsRow" style={{ marginTop: "20px" }}>

      <ThemedButtonIcon
        borderRadius={0}
        backgroundColor={"#9B1119"}
        color={'#fff'}
        name={"newyear23"}
        disableElevation={true}
        showIcon={items["newyear23"]}
        handlClick={handleClick}
        buttonText={"Chinese New Year 2023"}
      />

      <ThemedButtonIcon
        borderRadius={0}
        backgroundColor={"#9B1119"}
        color={'#fff'}
        name={"newyear24"}
        disableElevation={true}
        showIcon={items["newyear24"]}
        handlClick={handleClick}
        buttonText={"Chinese New Year 2024"}
      />
        <ThemedButtonIcon
        borderRadius={0}
        backgroundColor={"#9B1119"}
        color={'#fff'}
        name={"childrenday24"}
        disableElevation={true}
        showIcon={items["childrenday24"]}
        handlClick={handleClick}
        buttonText={"Children's Festival 2024"}
      />
      
      <ThemedButtonIcon
        borderRadius={0}
        backgroundColor={"#9B1119"}
        color={'#fff'}
        name={"newyear25"}
        disableElevation={true}
        showIcon={items["newyear25"]}
        handlClick={handleClick}
        buttonText={"Chinese New Year 2025"}
      />

    </Container>
    <Container className="pageMenuDetails">
      <div style={{ display: items["newyear23"] ? " block" : "none ", textAlign: "center", fontSize: '1.2rem' }} >
        {/* <p>2022-2023</p> */}
        <CarouselThumbNails images={images} thumbnails={thumbnails} />

        {/* <h3 className="galleryMore">Click <a href = "https://drive.google.com/drive/folders/12d1y55IDPrxNZ_Eiqls1uSf8h0bxE4gu" target="_blank"  rel="noopener noreferrer">here </a> for more photos from this event </h3> */}

       <p>Click <a  href="https://drive.google.com/drive/folders/12d1y55IDPrxNZ_Eiqls1uSf8h0bxE4gu" target="_blank" rel="noopener noreferrer">here</a> for more photos from this event.</p> 

      </div>

      {/* chinese new year 24 */}
      <div className="newYearGala" style={{ display: items["newyear24"] ? " block" : "none " }} >

        <a href="https://rdg.today/reading-celebrates-lunar-new-year-in-style/" target="_blank" rel="noopener noreferrer"><h1>Reading celebrates Lunar New Year in style</h1></a>
        <h2 style={{ marginLeft: "100px", textAlign: "center" }}> - RDG.TODAY</h2>
        <p style={{ marginTop: '30px', color: 'red', fontSize: '1.5rem' }}> Congratulations on the success of our Chinese New Year Gala at Reading Town Hall on 11th February! We are proud to be able to bring Chinese culture closer to the people of Reading. </p>
        {/* <CarouselThumbNails images={images} thumbnails={thumbnails}/> */}
 
        <div style={{ marginTop: "30px", textAlign: "center" }}>
          <video src={newYearVideo} width="75%" height="500" controls>
          </video>
        </div>

        {isOpen &&
          <LargeImageModal
            image={currentImage}
            alt=""
            onClose={() => setIsOpen(false)}
          />}

        <h2 style={{ marginTop: "30px", textAlign: "center" }} >Chinese Art & Culture workshops</h2>

        <ShowLargeImage
          images={workShops24}
          height="340"
          width="340"

          handleClick={(e) => handleAllImageClick(e, 0)} />
       
        <h2 style={{ marginTop: "30px", textAlign: "center" }} >Outdoor activities</h2>

        <ShowLargeImage
          images={outdoor24}
          height="340"
          width="340"

          handleClick={(e) => handleAllImageClick(e, 1)} />
     
        <h2 style={{ marginTop: "50px", textAlign: "center" }} >Performance</h2>

        <ShowLargeImage
          images={performance24}
          height="340"
          width="340"

          handleClick={(e) => handleAllImageClick(e, 2)} />

      </div>
      
      {/* children's day 24 */}

      <div  style={{ display: items["childrenday24"] ? " block" : "none ", textAlign: "center", fontSize: '1.2rem' }} >
        <p></p>

        <ShowVideo src={"https://www.youtube.com/embed/QIg9aWH9Odc?si=0SQjiFPTqYsNiqY2"} title={"Reading Children's Festival"}/>
        <ShowImageUnorderedList
          images={childrenPerform}
          height="340"
          width="340"

        />
        
      </div>

      <div className="newYearGala" style={{ display: items["newyear25"] ? " block" : "none " }} >
      <br />
      <br />

      <a href="https://mp.weixin.qq.com/s/sBqgUYIE8qhZgmTY1y4BNQ" target="_blank" rel="noopener noreferrer"><h1 style={{color:'red', textDecoration:"underline red"}}>金龙舞动雷丁城，中华文化耀英伦
      --雷丁中文学校蛇年春节庆典盛况空前</h1></a>
   

      {isOpen &&
          <LargeImageModal
            image={currentImage}
            alt=""

            onClose={() => setIsOpen(false)}
          />}
            
            <div style={{ textAlign: "center", padding: "20px", margin: "20px", border: " 1px solid grey"}}>
               

                <img src={RdgNews} alt="Chinse Calligraphy"   width={"80%"}   />
                <br />

                <br />
                <a href="https://rdg.today/a-sea-of-colour-as-reading-celebrates-the-year-of-the-snake/" target="_blank" rel="noopener noreferrer"><h5>Click here to read the full report in RDG.TODAY</h5></a>
         
              </div>


              <h2 style={{ marginTop: "30px", textAlign: "center", margin:"30px, 0px", color:'red' }} >春节活动精彩瞬间</h2>     
        <h2 style={{ marginTop: "10px", textAlign: "center", margin:"30px, 0px", color:'red' }} >Wonderful photos of Chinese New Year Gala</h2>


        {/* <ShowGalleryImages */}

        <ShowLargeImage
          images={newyear25}
          height="340"
          width="340"

          handleClick={(e) => handleAllImageClick(e, 3)} />
      </div>




    </Container>
  </Container >


  );
}
export default GalleryNew;