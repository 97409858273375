// import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const imageFolder = "/newyear"; // Images are inside public/images/
const imageCount = 4; // Update based on number of images

const HomeGallery = () => {
  const navigate = useNavigate();
  const images = Array.from({ length: imageCount }, (_, i) => `${imageFolder}/image${i + 1}.jpg`);


    const [index, setIndex] = useState(0);
  
    useEffect(() => {
      const interval = setInterval(() => {
        setIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 2000); // Change slide every 3 seconds
  
      return () => clearInterval(interval); // Cleanup on unmount
    }, [images.length]);
  
    return <img src={images[index]}   style={{ width:"90%",  margin:"20px auto"}}
             alt="Slide" onClick={() => { navigate("/gallery"); window.scrollTo(0, 0); }} />;
  

};


export default HomeGallery;
