import { Container, Card } from "react-bootstrap";
import { Button } from "@mui/material";
import staff from "../assets/team.png";
import course from "../assets/books.png";
import event from "../assets/event.png";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { createTheme, ThemeProvider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import wechatLogo from "../assets/RCS_Wechat_QRcode.png"
import customTheme from "../components/CustomTheme";
import HomeGallery from "../components/HomeGallery";


function Home() {

  // const theme = createTheme({
  //   components: {
  //     MuiButton: {
  //       styleOverrides: {
  //         root: {
  //           backgroundColor: "#9B1119",
  //           color: "#fff",
  //           '&:hover': {
  //             backgroundColor: '#fff',
  //             color: "#9B1119"
  //           },
  //           fontSize: "1rem",
  //         }
  //       }
  //     }
  //   }
  // });
  const themeWhite = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            backgroundColor: "#fff",
            color: '#9B1119',
            '&:hover': {
              backgroundColor: '#9B1119',
              color: '#fff'
            },
            fontSize: "1rem",
          }
        }
      }
    }
  });

  const navigate = useNavigate();


  return (
    <div className="homeDiv">
      <div>
        <Container fluid className=" homeContainer">

        </Container>
        <div className="homeOverlay" >
            <p className="homeOverlayLargeBold " >Welcome to Reading Chinese School</p>
            <div className="homePoster" style={{ textAlign: "justify", margin:"0px"}}>
                

              <div className="posterAndBooking" style={{width:"85%", margin:"20px auto"}} >
                 <HomeGallery /> 
                {/* new year poster */}
                {/* <img src={newyearposter} alt="New Year Poster" width={"70%"}  height={"20%"}  />   */}
                {/* <ThemeProvider theme={customTheme}>
                  <Button as="a" href="https://whatsonreading.com/venues/town-hall/whats-on/chinese-new-year-celebration-1" target="_blank" rel="noopener noreferrer"
                   variant="contained"  style={{ textDecoration: "none",  marginTop: "10px",
                   width: "100%", textAlign: "center", fontSize: "1rem"}}> Book Ticket</Button>
                 </ThemeProvider>   */}

                <ThemeProvider theme={themeWhite} >
          
                <Button variant="contained" className="homeOverlayButton"  style={{ textDecoration: "none",  marginTop: "10px",
                width: "90%", minHeight:"30px", fontSize:"1.2rem"}} onClick={() => { navigate("/gallery"); window.scrollTo(0, 0); }} endIcon={<ChevronRightIcon />} >Go To Gallery</Button> 
                </ThemeProvider>
              </div> 
         
            {/* <p className="homeOverlayMedium" >雷丁中文学校开设适合各种中文水平学生的课程，从亲子班、幼儿班到IGCSE、A Level 考试班和成人班，我们还有很多兴趣班以及丰富多彩的文化活动课供您体验，如太极，中国舞，书法，功夫，国学，11+ 和数学补习班等等，欢迎加入我们的大家庭！</p>
       */}
            {/* <p className="homeOverlayMedium" >好消息！以满足对中文学习有更高需求、在语言敏感期为孩子打好更深厚的根基，雷丁中文学校第一个《国学班》开始招生了！国学班将以儒家经典四书五经要义诵读为核心，
            用中英双语授课开拓思辨能力、知识体系。愿雷丁中文学校的国学班，能为英国的学子们种下中华文明为天地立心，吾心光明的种子。欢迎大家踊跃咨询报名！</p>
            <p className="homeOverlayMedium" >Good news! Reading Chinese School's first Guoxue (Chinese Classics) class is now open for enrollment! </p>
            <p className="homeOverlayMedium" >A parents inquiry session for the course will be held on Sunday 6th October, 10:00 – 11:00, Room H17.</p> */}

            <p className="homeOverlayMedium" > </p>
            </div >
            <div style={{marginTop: "0px"}}>
            {/* <ThemeProvider theme={themeWhite} >
             <Button variant="contained" className="homeOverlayButton"  style={{ textDecoration: "none",  marginTop: "10px",
                   width: "100%"}} onClick={() => { navigate("/gallery"); window.scrollTo(0, 0); }} endIcon={<ChevronRightIcon />} >More Information</Button> 
            </ThemeProvider> */}
            </div>

        </div>






        <Container className="homeAbout">
          <h1 className="homeOverlayLarge">About us</h1>
          <div >
            <p className="homeOverlayMedium">雷丁中文学校成立于1978年，我们的宗旨是向雷丁地区所有想学习了解中文和中华文化的人教授普通话以及中华文化。</p>
            <p className="homeOverlayMedium">Reading Chinese School was established in 1978. The school's aim is to provide education in both Chinese language and culture for children and adults of all ages and backgrounds. </p>


          </div>



          <Container className="cardContainer">
            <Card className="me-4 mb-4 pt-5 pb-5  col-md-4 box-shadow text-center">
              <Card.Img variant="top" style={{ minHeight: "100px" }} src={staff} alt="teacher" />
              <Card.Body>
                <Card.Text className="homeCardText">
                  <span>中文母语老师</span> <br />
                  <span> Native Chinese teachers</span>
                </Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
              </Card.Body>
            </Card>
            <Card className="me-4  mb-4 pt-5 pb-5 col-md-4 box-shadow text-center">
              <Card.Img variant="top" style={{ minHeight: "100px"}} src={course} alt="courses" />
              <Card.Body>

                <Card.Text className="homeCardText">
                  <span>适合各种水平的中文课程</span> <br />
                  <span>Courses for all levels</span>
                </Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
              </Card.Body>
            </Card>
            <Card className="me-4  mb-4 pt-5 pb-5  col-md-4 box-shadow text-center">
              <Card.Img variant="top" style={{ minHeight: "100px" }} src={event} alt="events" />
              <Card.Body>

                <Card.Text className="homeCardText">
                  <span>丰富多彩的文化活动</span> <br />
                  <span>Rich and colorful cultural <br /> activities</span>
                </Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
              </Card.Body>
            </Card>

          </Container>

          <div >
            <p className="homeOverlayMedium"> 雷丁中文学校欢迎三岁以上的所有学生加入这个美妙的中国语言文化学习旅程. </p>
            <p className="homeOverlayMedium">Reading Chinese School welcome students from all level (ages from 3 years old) to join our fantastic Chinese language and cultural learning journey. </p>

          </div>

          <div className="lessonTime">
            <div>
              <p className="homeOverlayMedium" >
                上课时间:<br /> 逢周日 10：00-12：00</p>

              <p className="homeOverlayMedium">Lesson time: <br />every Sunday 10:00-12:00</p>
              <p className="homeOverlayMedium">
                Address 地址:
              <br />
                Highdown School<br/>Surley Row, Emmer Green<br />Reading RG4 8LR</p>
            </div>

            <img src={wechatLogo} width={"300px"} alt="Wechat Logo" min-height="150px"></img>
          </div>


          <div className="moreButtonDiv">
            <ThemeProvider theme={customTheme}>
              <Button  variant="contained" className="buttonStyle mb-5" onClick={() => { navigate("/contact"); window.scrollTo(0, 0); }}
                endIcon={<ChevronRightIcon />} > More About Us</Button>
            </ThemeProvider>
          </div>
          

        </Container>
        {/* <Button variant="secondary" size="lg" className="m-auto">More About Us</Button> */}





      </div>
    </div>)

}

export default Home;
