import { Container } from "react-bootstrap";
import { useState } from "react";
import ThemedButtonIcon from "../components/ThemedButtonIcon";
// import customTheme from "../components/CustomTheme";
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import { useNavigate } from "react-router-dom";
// import newyearposter from "../assets/others/2025poster.jpg";
// import { Button, ThemeProvider } from "@mui/material";


function Events() {

  const [items, setItems] = useState({ upcoming: true });

  function handleClick(e) {

    const name = e.target.name;
    setItems(() => {
      var itemsSelected = {
        upcoming: false,
        latest: false,
        year22to23: false,
      }
      itemsSelected[name] = true;
      return itemsSelected;
    })
  }

  // const navigate = useNavigate();
  return (
    <div>
      <Container fluid className="pageContainer">
        <div className="pageTitleDiv">EVENTS</div>
        <Container fluid className="pageImageContainer">

        </Container>
        <Container className="pageDetails">
          <div className="pageMenuButtons">

            <Container style={{ height: "80px" }}>
              <p style={{ fontSize: "1.6rem", color: "white", textAlign: "left", fontWeight: "500" }}>  In this section</p>
            </Container>

            <ThemedButtonIcon
              borderRadius={0}
              backgroundColor={"#9B1119"}
              color={'#fff'}
              name={"upcoming"}
              disableElevation={true}
              showIcon={items["upcoming"]}
              handlClick={handleClick}
              buttonText={"Upcoming Events"}
            />

            <ThemedButtonIcon
              borderRadius={0}
              backgroundColor={"#9B1119"}
              color={'#fff'}
              name={"latest"}
              disableElevation={true}
              showIcon={items["latest"]}
              handlClick={handleClick}
              buttonText={"Latest Events"}
            />
            <ThemedButtonIcon
              borderRadius={0}
              backgroundColor={"#9B1119"}
              color={'#fff'}
              name={"year22to23"}
              disableElevation={true}
              showIcon={items["year22to23"]}
              handlClick={handleClick}
              buttonText={"2022-2023 School Year"}
            />

          </div>

          <Container className="pageMenuDetails" style={{ marginTop: "20px" }} >

            <div className="sectionDiv" style={{ display: items["upcoming"] ? " block" : "none " }} >


              <h5 className="upcomingEvents"> April <br />朗诵比赛 Reciting competition</h5>
           
           
              <div className="posterAndBooking">

                {/* for chinese new year poster and booking link */}
                {/* <img src={newyearposter} alt="New Year Poster" minWidth={"300px"} width={"60%"} marginBottom={"30px"} />
                <ThemeProvider theme={customTheme}>
                  <Button as="a" href="https://whatsonreading.com/venues/town-hall/whats-on/chinese-new-year-celebration-1" target="_blank" rel="noopener noreferrer" variant="contained" className="mt-2 mb-5" style={{ textAlign: "center", textDecoration: "none", padding: "10px 30px" , width: "80%"}}> Book Ticket</Button>
                </ThemeProvider> */}

              </div>
            </div>


            <div className="sectionDiv" style={{ display: items["latest"] ? " block" : "none " }} >

              <a href="https://mp.weixin.qq.com/s/sBqgUYIE8qhZgmTY1y4BNQ?poc_token=HL19r2ej-LALHDTXPVbu_jO3TKlyWLwNYbxKkEdH" target="_blank" rel="noopener noreferrer">
              <h4>金龙舞动雷丁城，中华文化耀英伦 ——雷丁中文学校蛇年春节庆典盛况空前</h4></a>
              <h6 className="blogEvents" style={{ fontSize: "15px", marginBottom: "20px" }}>2025-02-14</h6>

              <a href="https://mp.weixin.qq.com/s/KmtSPIzNa0QNNC0m_dKdQg" target="_blank" rel="noopener noreferrer">
              <h4>雷丁中文学校鸿运舞团 舞蹈《一条大河》</h4></a>
              <h6 className="blogEvents" style={{ fontSize: "15px", marginBottom: "20px" }}>2025-02-14</h6>              

              <a href="https://mp.weixin.qq.com/s/N1ZamzOVNp7ircqVtMotoA" target="_blank" rel="noopener noreferrer"><h4>英国雷丁中文学校举办首届中文IGCSE庆典</h4></a>
              <h6 className="blogEvents" style={{ fontSize: "15px", marginBottom: "20px" }}>2024-12-15</h6>            

              <a href="https://mp.weixin.qq.com/s/mhqRNDmuM0l964Z0dhgW4A" target="_blank" rel="noopener noreferrer"><h4>我校在雷丁市中心举办文化工作坊</h4></a>
              <h6 className="blogEvents" style={{ fontSize: "15px", marginBottom: "20px" }}>2024-06-25</h6>

              <a href="https://mp.weixin.qq.com/s/7pxKPBV_ubnXiIHwtLmBkg" target="_blank" rel="noopener noreferrer"><h4>我校在“第二十一届全英普通话朗诵比赛暨第七届全球华语朗诵大赛”中取得佳绩</h4></a>
              <h6 className="blogEvents" style={{ fontSize: "15px", marginBottom: "20px" }}>2024-06-25</h6>

              <a href="https://mp.weixin.qq.com/s/cO_mCxLo12gF6fPHND--Cw" target="_blank" rel="noopener noreferrer"><h4>中文学校闪耀雷丁儿童节</h4></a>
              <h6 className="blogEvents" style={{ fontSize: "15px", marginBottom: "20px" }}>2024-05-18</h6>


              <a href="https://mp.weixin.qq.com/s/fN30wXq7sZI4Y1vfJQX2YQ" target="_blank" rel="noopener noreferrer"><h4>雷丁中文学校隆重举办瑞龙迎春贺新年活动</h4></a>
              <h6 className="blogEvents" style={{ fontSize: "15px", marginBottom: "20px" }}>2024-02-16</h6>


              <a href="https://mp.weixin.qq.com/s/2GRj6kcPkZNWxLaXMQeO1g" target="_blank" rel="noopener noreferrer"><h4>喜报 | 我校学子在2023届“华文创想曲”暨海外华裔青少年创意作文大赛中荣获佳绩</h4></a>
              <h6 className="blogEvents" style={{ fontSize: "15px", marginBottom: "20px" }}>2024-02-09</h6>
              <a href="https://mp.weixin.qq.com/s/OGjdb_2t2DRqdCkFLSIfug" target="_blank" rel="noopener noreferrer"><h4>纸短情长 贺新年！</h4></a>
              <h6 className="blogEvents" style={{ fontSize: "15px", marginBottom: "20px" }}>2024-02-09</h6>

              <a href="https://mp.weixin.qq.com/s/Nndcj_LNLqTEP7Vf8ivGMg" target="_blank" rel="noopener noreferrer"><h4>海外家庭如何打造双语学习氛围座谈会</h4></a>
              <h6 className="blogEvents" style={{ fontSize: "15px", marginBottom: "20px" }}>2023-10-24</h6>

              <a href="https://mp.weixin.qq.com/s/hJncGh1vrG-ZeN6kanbslw" target="_blank" rel="noopener noreferrer"><h4>迎庆双节、贺校庆——雷丁中文学校隆重举办建校四十五周年庆典</h4></a>
              <h6 className="blogEvents" style={{ fontSize: "15px", marginBottom: "20px" }}>2023-10-09</h6>

              <a href="https://mp.weixin.qq.com/s/Zv7NWHxmSjGGxK_gtBgk6Q" target="_blank" rel="noopener noreferrer"><h4>雷丁中文学校举办大学专业选择及职业规划讲座</h4></a>
              <h6 className="blogEvents" style={{ fontSize: "15px", marginBottom: "20px" }}>2023-10-03</h6>

              <a href="https://mp.weixin.qq.com/s/MdMmUgHAj4tS1ZNJjIjo8w" target="_blank" rel="noopener noreferrer"><h4>迎新聚力，我们开学啦</h4></a>
              <h6 className="blogEvents" style={{ fontSize: "15px", marginBottom: "20px" }}>2023-09-15</h6>




            </div>

            <div className="sectionDiv" style={{ display: items["year22to23"] ? " block" : "none " }} >
              <a href="https://mp.weixin.qq.com/s/J80CNbmQlrdwtI0hrNsPhA" target="_blank" rel="noopener noreferrer"><h4>硕果累累，喜报频传——雷丁中文学校2022-2023学年颁奖典礼</h4></a>
              <h6 className="blogEvents" style={{ fontSize: "15px", marginBottom: "20px" }}>2023-07-10</h6>

              <a href="https://mp.weixin.qq.com/s/TokBM3IiTHN2-v-WKMc1_A" target="_blank" rel="noopener noreferrer"><h4>趣享运动，释放活力——雷丁中文学校趣味运动会精彩记录</h4></a>
              <h6 className="blogEvents" style={{ fontSize: "15px", marginBottom: "20px" }}> 2023-07-07</h6>


              <a href="https://mp.weixin.qq.com/s/RTUFaOl0EJTk49qRzs-Cog" target="_blank" rel="noopener noreferrer"><h4>粽香四溢，浓情端午——雷丁中文学校师生共同庆祝端午佳节</h4></a>
              <h6 className="blogEvents" style={{ fontSize: "15px", marginBottom: "20px" }}>2023-06-21</h6>


              <a href="https://mp.weixin.qq.com/s/vAxc_PP6Rd2N74XMxGiIVA" target="_blank" rel="noopener noreferrer"><h4>雷丁中文学校“浓情母亲节，鲜花义卖活动”</h4></a>
              <h6 className="blogEvents" style={{ fontSize: "15px", marginBottom: "20px" }}> 2023-03-21</h6>

              <a href="https://mp.weixin.qq.com/s/p64UQ-fISln0w1ZnasP4mg" target="_blank" rel="noopener noreferrer"><h4>喜报！！！雷丁中文学校2022年“华文创想曲”海外华裔青少年创意作文大赛获奖名单</h4></a>
              <h6 className="blogEvents" style={{ fontSize: "15px", marginBottom: "20px" }}> 2023-03-15 </h6>


              <a href="https://mp.weixin.qq.com/s/NYmjvg5fSedMT_oF8SVrrg" target="_blank" rel="noopener noreferrer"><h4>中文学校老师加班加点参加线上培训，推动教学标准化，提高教学质量</h4></a>
              <h6 className="blogEvents" style={{ fontSize: "15px", marginBottom: "20px" }}>2023-03-15 </h6>

              <a href="https://mp.weixin.qq.com/s/9lKFkfkX3Rl624sTfpTsXw" target="_blank" rel="noopener noreferrer"><h4>大爱无国界，雷丁中文学校为土耳其、叙利亚地震灾民募爱心</h4></a>
              <h6 className="blogEvents" style={{ fontSize: "15px", marginBottom: "20px" }}>2023-03-15 </h6>

              <a href="https://mp.weixin.qq.com/s/3KF83WaOUcnzVkdmmxJYYQ" target="_blank" rel="noopener noreferrer"><h4>玉兔送福庆新春 中华文化氛围浓——雷丁中文学校成功举办2023新春联欢会</h4></a>
              <h6 className="blogEvents" style={{ fontSize: "15px", marginBottom: "20px" }}> 2023-02-07</h6>

            </div>
          </Container>
        </Container>
      </Container>

    </div>);

}

export default Events;
